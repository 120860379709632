<template>
  <el-upload
    v-model:file-list="fileList"
    ref="upload"
    :limit="1"
    :auto-upload="false"
    :on-change="changeUpload"
    accept=".mp4"
    action=""
    list-type="picture-card"
    class="upload-image"
    :class="{ hide: hideUpload }"
  >
    <el-icon><Plus /></el-icon>
    <template #file="{ file }">
      <div v-if="media_url" class="image-file">
        <video style="width: 100%">
          <source :src="media_url" type="video/mp4" />
        </video>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="dialogVisible = true">
            <el-icon><zoom-in /></el-icon>
          </span>
          <span class="el-upload-list__item-delete" @click="removeVideo(file)">
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
      <span v-if="media_name" class="image-name">
        檔名：{{ media_name?.length > 20 ? `${media_name.slice(0, 17)}...` : media_name }}
      </span>
    </template>
  </el-upload>
  <!-- 進度條 -->
  <div v-if="showPercentage" class="video-progress">
    <el-progress
      v-if="showPercentage"
      :text-inside="true"
      :stroke-width="24"
      :percentage="percentage"
      status="success"
      style="width: 250px"
    />
  </div>
  <!-- 影片放大 -->
  <el-dialog v-model="dialogVisible" width="80%" :destroy-on-close="true">
    <video style="width: 100%" controls>
      <source :src="media_url" type="video/mp4" />
    </video>
  </el-dialog>
</template>
<script>
import { uploadVideo } from '@/api/common';
import { ref, computed, getCurrentInstance } from 'vue';
export default {
  name: 'upload-video',
  props: ['file_url', 'file_name', 'loadingVideo'],
  emits: ['update:file_url', 'update:file_name', 'update:loadingVideo'],
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const upload = ref(null);
    const fileList = ref([]);
    const hideUpload = ref(false);
    const dialogVisible = ref(false);
    const percentage = ref(0);
    const showPercentage = computed({
      get: () => props.loadingVideo,
      set: (val) => context.emit('update:loadingVideo', val),
    });
    const media_url = computed({
      get: () => props.file_url,
      set: (val) => context.emit('update:file_url', val),
    });
    const media_name = computed({
      get: () => props.file_name,
      set: (val) => context.emit('update:file_name', val),
    });
    //初始化
    if (media_url.value) {
      fileList.value.push({ name: media_name.value, url: media_url.value });
      hideUpload.value = true;
    }
    //檢查影片
    const checkVideo = (file) => {
      const isLtMB = file.size / 1024 / 1024 <= 100;
      if (!isLtMB) {
        upload.value.handleRemove(file);
        proxy.$message.error('檔案大小不得超過100MB');
        return Promise.reject();
      }
      return isLtMB;
    };
    //上傳影片
    const changeUpload = async (file) => {
      await checkVideo(file);
      hideUpload.value = true;
      fileList.value = [];
      try {
        const onUploadProgress = (progress) => {
          percentage.value = Math.round((progress.loaded * 100) / progress.total);
          showPercentage.value = true;
        };
        let data = new FormData();
        data.append('file', file.raw);
        data.append('path', 'slideshow');
        const res = await uploadVideo(data, onUploadProgress);
        media_url.value = res.url;
        media_name.value = file.name;
        percentage.value = 0;
        showPercentage.value = false;
        fileList.value.push(file);
      } catch (e) {
        upload.value.handleRemove(file);
        hideUpload.value = false;
        percentage.value = 0;
        showPercentage.value = false;
      }
    };
    //刪除影片
    const removeVideo = (file) => {
      media_url.value = null;
      media_name.value = null;
      upload.value.handleRemove(file);
      hideUpload.value = false;
      percentage.value = 0;
      showPercentage.value = false;
    };
    return {
      upload,
      fileList,
      hideUpload,
      percentage,
      showPercentage,
      media_url,
      media_name,
      dialogVisible,
      changeUpload,
      removeVideo,
    };
  },
};
</script>
<style lang="scss" scoped>
.image-file {
  @include flex-center-center;
}
.image-name {
  position: absolute;
  bottom: -30px;
  font-size: 14px;
}
.video-progress {
  width: 300px;
  height: 300px;
  border-radius: 6px;
  border: 1px solid $el-border-color-lighter;
  position: absolute;
  top: 42px;
  @include flex-center-center;
}
:deep(.el-upload--picture-card) {
  --el-upload-picture-card-size: 300px;
}
:deep(.el-upload-list--picture-card) {
  --el-upload-list-picture-card-size: 300px;
}
.hide :deep(.el-upload--picture-card) {
  display: none;
}
.upload-image {
  :deep(.el-upload-list__item) {
    overflow: visible;
  }
}
</style>
